<script>
export default{
  model: {
    prop: 'value',
    change: 'onValueChanged',
  },
  props:{
    value: { type:String, required: true},
    required: { type:Boolean, required: true, default: false },
    minLength: { type:Number, required: true, default: 0 },
    maxLength: { type:Number, default: null},
    requiredErrorMessage: { type: String, default: 'Ce champ est requis !'},
    minLengthEerrorMessage: { type:String, default: `Ce champ doit faire ${this.minLength} caractères au minumum !`},
    maxLengthEerrorMessage: { type:String, default: `La longueur de ce champ ne doit pas excéder ${this.maxLength} !`},
  },
  computed:{
    isRequiredValidationOk(){
      if(this.required){
        if(this.value === undefined || this.value === null)
          return false;
        if(this.value.trim().length == 0)
          return false;
      }
      return true;
    },
    isMinLengthValidationOk(){
      if(this.minLength == 0)
        return true;
      if(!this.isRequiredValidationOk)
        return false;
      return this.value.length >= this.minLength;
    },
    isMaxLengthValidationOk(){
      if(this.maxLength === undefined || this.maxLength === null || this.maxLength <= 0)
        return true;
      if(!this.isRequiredValidationOk)
        return false;
      return this.value.length <= this.maxLength;
    },
    isValid(){
      return this.isRequiredValidationOk && this.isMinLengthValidationOk && this.isMaxLengthValidationOk;
    }
  },
  methods:{
    onValueChanged(){
      console.log('onValueChanged');
    }
  }
}
</script>
