<template>
  <div style="width:80%; display:flex; flex-direction: column">
    <h1>Edition d'une opération</h1>
    <secured-area :operations="['View_Operation', 'Edit_Operation', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles']">
        <collapse-panel title="Généralités">
          <detail
            v-model="operation"
            @submit="saveOperation"
            @reset="loadAndRefreshOperation"
          />
        </collapse-panel>
    </secured-area>
    <secured-area :operations="['View_Operations_For_Roles', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role']" :hideWhenAccessIsRejected="true">
       <collapse-panel title="Roles">
          <roles
            v-model="roles"
            @submit="saveOperationRoles"
            @reset="loadAndRefreshOperationRoles"
          />
        </collapse-panel>
    </secured-area>
  </div>
</template>

<script>
  import SecuredArea from '@/components/Security/SecuredArea.vue';
  import Detail from "@/components/Security/Operations/Detail.vue";
  import Roles from '@/components/Security/Operations/Roles.vue';
  import axios from 'axios';
  import CollapsePanel from '@/components/Panels/CollapsePanel.vue';

  export default {
    components: { SecuredArea, Detail, Roles, CollapsePanel},
    data(){
        return{
            id: null,
            operation: null,
            users: [],
            roles: [],
        }
    },
    methods:{
      loadAndRefreshOperation(){
        this.loadAndRefresh(true, false, false);
      },
      loadAndRefreshOperationUsers(){
        this.loadAndRefresh(false, true, false);
      },
      loadAndRefreshOperationRoles(){
        this.loadAndRefresh(false, false, true);
      },
      loadAndRefresh(withOperation = true, withUsers = true, withRoles = true){
        if(this.id != null)
        {
          var url = this.getUrl('/api/Security/getOperation.php');

          var criteria = { operation_id: this.id, includeOperation: withOperation, includeUsers: withUsers, includeRoles: withRoles };
          console.log(url, criteria);
          axios.post(url, criteria)
            .then((response) => {
              console.log('getOperation => ', response.data);
              if(withOperation) { this.operation = response.data.operation; }
              if(withUsers) { this.users = response.data.users; }
              if(withRoles) { this.roles = response.data.roles; }
            })
            .catch(error => {
              console.log(error);
            })
        }
      },
      saveOperation(buffer){
        console.log('saveRole', buffer);
       },
      saveOperationRoles(buffer){
          var url = this.getUrl('/api/Security/grantOperationToRoles.php');
          var args = { operation: this.operation.name, roles: buffer.map(o => o.name).join(), replaceExisting: true };
          axios.post(url, args)
            .then(response => {
              if(response.data.success){
                this.roles = buffer;
                this.toastSaved('Les rôles autorisés à traiter cette opération ont bien été mis à jour.');
              } else {
                this.toastSaveFailure(response.data.error);
              }
            })
            .catch(error => {
              console.log(error);
              this.toastError();
            })
      },
    },
    mounted(){
      this.id = this.$route.params["id"];
      this.loadAndRefresh();
      this.$hideLoader();
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                console.log("/Security/Operations/Edit.vue > route id changed : ", to);
                this.id = to;
                this.loadAndRefresh();
            }
        },
    },
  }
</script>
