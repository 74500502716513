<template>
  <secured-area
    :operations="['View_Operation', 'Edit_Operation', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles']"
    :hideWhenAccessIsRejected="true"
  >
    <b-form @reset="onReset">
      <b-form-row>
        <b-col>
          <form-field
            :compactMode="compactMode"
            caption="Nom technique (Code) de l'opération"
            >
            <template #info-helper-placeholder>
                <info-helper>
                  <div><b>Nom technique</b> ou <b>Code</b> = Le code interne permettant qui est utilisé partout dans l'application pour véirifer qu'un utilisateur peut ou ne peut pas réaliser l'opération désiqnée.</div>
                  <!--<div>Représente le nom avec lequel l'utilisateur va pouvoir se connecter via l'écran de connexion lui demandant la saisie de son <b>Login</b> et <b>Mot de passe</b></div>-->
                </info-helper>
            </template>
            <b-form-input type="text" :readonly="true"  v-model="buffer.name"/>
          </form-field>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <form-field :compactMode="compactMode" caption="Nom public (d'affichage)"
            :invalidFeedback="invalidDisplayNameMessage"
            :state="isDisplayNameValid"
            :required="true"
          >
            <b-form-input type="text" :readonly="isReadonly('buffer.displayName')" v-model="buffer.displayName" required :state="isDisplayNameValid" />
            <text-validator v-model="buffer.displayName" />
          </form-field>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <form-field :compactMode="compactMode" :required="true" caption="Description"
            :invalidFeedback="invalidDescriptionMessage"
            :state="isDescriptionValid"
          >
            <b-textarea :readonly="isReadonly('buffer.description')" v-model="buffer.description" :state="isDescriptionValid" />
            <text-validator v-model="buffer.description" />
          </form-field>
        </b-col>
      </b-form-row>

      <form-bottom-action-bar>
         <template #right>
          <b-button type="reset" v-show="userCan('Edit_Operation')" :disabled="!hasChanged">Annuler</b-button>
          <b-button type="submit" v-show="userCan('Edit_Operation')" :disabled="!hasChanged">Enregistrer</b-button>
        </template>
      </form-bottom-action-bar>
    </b-form>
  </secured-area>
</template>

<script>

import { mapActions } from 'vuex';
import InfoHelper from '@/components/InfoHelper.vue';
import SecuredArea from '@/components/Security/SecuredArea.vue';
import FormField from '@/components/Panels/FormField.vue';
import FormBottomActionBar from '@/components/FormBottomActionBar';
//import { TextValidator } from './TextValidator';
import TextValidator from './TextValidator.vue';


export default{
  components: { InfoHelper, SecuredArea, FormField, FormBottomActionBar, TextValidator },
  props: { editedOperation: { type:Object, default:null }, compactMode: { type:Boolean, default:false}},
  model: {
    prop: 'editedOperation',
  },
  data(){
    return {
      userCanEdit: false,
      userCanView: false,

 /*     userCanAddUser: false,
      userCanRemoveUser: false,*/
      //lastRightsCheck: null,

      buffer: {},
      //name: '',
      //displayName: '',
      //description: '',
      //isActive: false,

      //displayNameRequiredValidator : new TextRequiredFieldValidator(false),
      //descriptionRequiredValidator : new TextRequiredFieldValidator(false),
      nameMaxLength: 50,
      displayNameMaxLength: 100,
      descriptionMaxLength: 4096,

      hasChanged: false,
    }
  },
  computed:{
/*    isNameValid(){
      return !(this.buffer.name.trim().length > 0 && this.buffer.name.trim().length <= this.nameMaxLength) ? false : null;
    },
    invalidNameMessage(){
      var trimmed = this.name.trim();
      if(trimmed.length == 0)
        return "Le Nom (technique) du rôle est requis !";
      if (trimmed.length > this.nameMaxLength)
        return `Le Nom (technique) du rôle ne peut pas excéder ${this.nameMaxLength} caractères.`;
      return "Ce nom (technique) n'est pas valide.";
    },*/
    isDisplayNameValid(){
      return !(this.buffer.displayName != null && this.buffer.displayName.trim().length > 0 && this.buffer.displayName.trim().length <= this.displayNameMaxLength) ? false : null;
    },
    invalidDisplayNameMessage(){
      var trimmed = (this.buffer.displayName == null ? '' : this.buffer.displayName).trim();
      if(trimmed.length == 0)
        return "Le nom (public) de l'opération est obligatoire.";
      if (trimmed.length > this.displayNameMaxLength)
        return `Le nom (public) de l'opération ne peut pas excéder ${this.displayNameMaxLength} caractères.`;
      return "Ce nom (public) n'est pas valide.";
    },
    isDescriptionValid(){
      return !(this.buffer.description == null && this.buffer.description.trim().length <= this.buffer.descriptionMaxLength) ? false: null;
    },
    invalidDescriptionMessage(){
      var trimmed = (this.buffer.description == null ? '' : this.buffer.description).trim();
      if (trimmed.length > this.descriptionMaxLength)
        return `La description de l'opération ne peut pas excéder ${this.descriptionMaxLength} caractères.`;
      return "Cette description n'est pas valide.";
    },
  },
  methods: {
    ...mapActions('auth', ['userCan']),
    async refreshRights(){
      this.userCanVview = await this.userCan('View_Operation');
      this.userCanEdit = await this.userCan('Edit_Operation');
      /*this.userCanAddUser = await this.userCan("Add_User_To_Role");
      this.userCanRemoveUser = await this.userCan('Remove_User_From_Role');
      this.userCanGrantOperation = await this.userCan('Grant_Operation_To_Role');
      this.userCanRevokeOperation = await this.userCan('Revoke_Operation_From_Role');*/
      return true;
    },
    isReadonly(){
      return !this.userCanEdit;
    },
    initBuffer(value){
      this.buffer = (value != null)
                  ? JSON.parse(JSON.stringify(value))
                  : { name: '', displayName: '', description: '', isActive:false} ;
    },
    onSubmit(e){
      console.log('onSubmit', e);
      this.$emit('submit', this.buffer);
    },
    onReset(e){
      e.preventDefault();
      this.initBuffer(this.editedOperation);
      console.log('onReset', e);
      this.$emit('reset');
      this.hasChanged = false;
    }
  },
  watch: {
    editedOperation: function(newVal, oldVal) { // watch it
      console.log('operation changed: ', newVal, ' | was: ', oldVal);
      this.initBuffer(newVal);
    }
  },
  async mounted(){
    await this.refreshRights();
    this.initBuffer(this.editedOperation);
  }
}
</script>

<style scoped>
  div.input-group { margin-top: 5px; margin-bottom:5px; margin-left: 15px; width: calc(100% - 30px); }
  div.input-group.required .input-group-text::after { content:'* '; color:var(--ffvlAlert);}
</style>
