<template>
  <div class="form-bottom-action-bar">
    <div class="left"><slot name="left"></slot></div>
    <slot></slot>
    <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script >
export default{}
</script>

<style scoped>
  div.form-bottom-action-bar { padding:15px; text-align: center;}
  div.form-bottom-action-bar > .left { float: left; }
  div.form-bottom-action-bar > .right { float: right; }
</style>
