<template>
<secured-area :operations="['View_Roles_Operations', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role']">
  <b-checkbox switch v-model="showDisplayName" button-variant="info" > &gt; <i>{{ showDisplayName ? 'Noms techniques' : 'Noms publics'}}</i></b-checkbox>
  <b-form>
    <ul class="flex">
      <li v-for="role in roles" :key="role.name" class="">
        <b-checkbox :checked="isSet(role)" :class="{'isSet':isSet(role)}" :disabled="!canChange(role)" @change="toggle(role)" :id="'role_' + role.name ">
          <label :for="'role_' + role.name"> {{ showDisplayName ? role.displayName : role.name }}</label>
          <info-helper v-if="role.description && !role.description == ''">
            {{ role.description }}
          </info-helper>
        </b-checkbox>
      </li>
    </ul>
    <form-bottom-action-bar>
      <template #right>
        <b-button type="reset" v-show="userCan('Grant_Operation_To_Role') || userCan('Revoke_Operation_From_Role')" :disabled="!hasChanged" >Annuler</b-button>
        <b-button type="submit" v-show="userCan('Grant_Operation_To_Role') || userCan('Revoke_Operation_From_Role')" :disabled="!hasChanged">Enregistrer</b-button>
      </template>
    </form-bottom-action-bar>
  </b-form>
</secured-area>
</template>

<script>
import axios from "axios";
import SecuredArea from '@/components/Security/SecuredArea.vue';
import FormBottomActionBar from '@/components/FormBottomActionBar';
import { mapActions } from 'vuex';
import InfoHelper from '../../InfoHelper.vue';
//import FormActionButtonsArea from '@/components/FormActionButtonsArea.vue';

export default {
  components: { SecuredArea, FormBottomActionBar, InfoHelper/*, FormActionButtonsArea*/ },
  model: {
    prop: 'editedRoles',
  },
  props: {
    editedRoles: { type: Array, required: true },
    showDisplayNames: { type: Boolean, default: true },
  },
  data(){
    return {
      roles: [],
      buffer: [],
      hasChanged:false,
    }
  },
  methods:{
    ...mapActions('auth', ['userCan']),
    isSet(role)
    {
      if(this.buffer && this.buffer.length > 0){
        return this.buffer.findIndex(ur => ur.trim().toLowerCase() == role.name.trim().toLowerCase()) >= 0;
      }
      return false;
    },
    refresh(){
      var url = this.getUrl('/api/Security/getRoles.php');

      axios.get(url)
        .then(response=>{
          this.roles = response.data.roles;
        })
        .catch(error => {
          console.log('Roles refresh error', error);
        });
    },
    canChange(role)
    {
      if(this.isSet(role)){
        return this.userCan('Revoke_Operation_From_Role');
      }else{
        return this.userCan('Grant_Operation_To_Role');
      }
    },
    toggle(role)
    {
      console.log('toggle', role);
      if(this.isSet(role)){
        this.buffer = this.buffer.filter(r => r.trim().toLowerCase() != role.trim().toLowerCase());
      }else{
        this.buffer.push(role);
      }
      this.hasChanged = this.checkHasChanged();
    },
    getFinalState(){
      var state = this.editedRoles;
      return state;
    },
    checkHasChanged(){
      if(this.buffer.length != this.editedRoles.length) {
        return true;
      }
      const initState = this.editedRoles.sort((a,b) => a.localeCompare(b, undefined, 'base'));
      const newState = this.buffer.sort((a,b) => a.localeCompare(b, undefined, 'base'));
      return newState.join() != initState.join();
    },
    onSumbit(e){
      this.console.log('onSubmit', e);
      this.$emit('submit', this.buffer);
    },
    onReset(e){
      e.preventDefault();
      this.buffer = this.clone(this.editedRoles);
      this.$emit('reset');
      this.hasChanged = this.checkHasChanged();
    },
    clone(source){
      return JSON.parse(JSON.stringify(source));
    }
  },
  mounted(){
    this.buffer = (this.editedRoles == null) ? [] : this.clone(this.editedRoles);
    this.refresh();
  },
    watch: {
    editedRoles: function(newVal, oldVal) { // watch it
      console.log('user changed: ', newVal, ' | was: ', oldVal);
      this.buffer = (newVal == null) ? [] : this.clone(newVal);
      this.refresh();
    }
  },
}
</script>

<style scoped>
  ul { display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row; flex-wrap: wrap; }
  li { list-style-type: none; width: 50%; padding:10px; }
  .info-text { margin-left: 15px; }
  /*li:first-child{ margin-right: 20px;}*/
    .isSet { color:saddlebrown; font-weight: bold; }
</style>

